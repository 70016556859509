import { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle as MuiDialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import CountrySelect from "../UI/CountrySelect";
import { AppConfig } from "../../../configs/admin-config";
import { AddCircleOutline as AddCircleOutlineIcon, Close as CloseIcon } from "@material-ui/icons";
import { getAccessToken } from "../../../utils/oauth";

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  tableContainer: {
    marginTop: 20,
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.light,
  },
  column: {
    cursor: "pointer",
    "&:hover": {
      fontWeight: 600,
    },
  },
  tableRow1: {
    "&:hover": {
      backgroundColor: "#F5F5F5",
      cursor: "pointer",
    },
  },
  tableRow2: {
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: "bold",
  },
  secondaryText: {
    color: theme.palette.secondary.main,
    fontSize: 12,
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "white",
    textTransform: "none",
    fontSize: 11,
  },
  addButton: {
    textTransform: "none",
  },
  dialog: {
    border: "1px solid black",
  },
  dialogActions: {
    paddingRight: 23,
    paddingTop: 15,
    paddingBottom: 20,
  },
  msgTypo: {
    paddingTop: 20,
    textAlign: "center",
    color: theme.palette.secondary.main,
  },
  infoMsg: {
    marginTop: 10,
    color: "grey",
  },
  errorMsg: {
    color: "red",
    fontSize: 12,
    marginBottom: 5,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

const NewResellerDialog = (props) => {
  const classes = useStyles();
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState(false);
  const [contactEmail, setContactEmail] = useState("");
  const [contactEmailError, setContactEmailError] = useState(false);
  const [channelManagerEmailError, setChannelManagerEmailError] = useState(false);
  const [channelManagerEmail, setChannelManagerEmail] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNameError, setContactNameError] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidEmail2, setInvalidEmail2] = useState(false);
  const [formRender, setFormRender] = useState(0);
  const [region, setRegion] = useState(null);
  const [regionError, setRegionError] = useState(false);
  const [snack, setSnackBar] = useState({ visibility: false, serverity: "", message: "" });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, data, ...other } = props;
    return (
      <MuiDialogTitle className={classes.root} {...other}>
        <Grid container direction="row" justifycontent="space-between" alignItems="center">
          <Grid item xs>
            <Typography variant="h4">{children}</Typography>
          </Grid>
          <Grid item>
            {handleDialogClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </MuiDialogTitle>
    );
  });

  const companyNameHandler = (e) => {
    setCompanyNameError(false);
    setCompanyName(e.target.value);
  };

  const contactNameHandler = (e) => {
    setContactNameError(false);
    setContactName(e.target.value);
  };

  const contactEmailHandler = (e) => {
    setContactEmailError(false);
    setInvalidEmail(false);
    setContactEmail(e.target.value);
  };

  const channelManagerEmailHandler = (e) => {
    setChannelManagerEmailError(false);
    setInvalidEmail2(false);
    setChannelManagerEmail(e.target.value);
  };

  const handleDialogClose = () => {
    props.handleDialogClose(false);
  };

  const handleRegion = (event) => {
    setRegionError(false);
    setRegion(event.target.value);
  };

  const validateForm = () => {
    let result = true;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (companyName === "") {
      result = false;
      setCompanyNameError(true);
    }
    if (contactName === "") {
      result = false;
      setContactNameError(true);
    }
    if (contactEmail === "") {
      result = false;
      setContactEmailError(true);
    }
    if (channelManagerEmail === "") {
      result = false;
      setChannelManagerEmailError(true);
    }
    if (contactEmail !== "" && !re.test(String(contactEmail).toLowerCase())) {
      result = false;
      setInvalidEmail(true);
    }
    if (channelManagerEmail !== "" && !re.test(String(channelManagerEmail).toLowerCase())) {
      result = false;
      setInvalidEmail2(true);
    }
    if (!region) {
      result = false;
      setRegionError(true);
    }
    return result;
  };

  const addCompany = async () => {
    if (validateForm()) {
      setCompanyName("");
      setContactEmail("");
      setContactName("");
      setChannelManagerEmail("");
      const requestURL = AppConfig.baseUrl + AppConfig.partnerLink;

      let data = {
        contactEmail: contactEmail,
        contactName: contactName,
        companyName: companyName,
        channelManagerEmail: channelManagerEmail,
        clientUrl: AppConfig.externalUrl,
        regionId: region,
      };
      try {
        let response = await fetch(requestURL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAccessToken(),
          },
          body: JSON.stringify(data),
        });
        let body = await response.json();
        // setbackDrop(false)
        if (response.status === 201) {
          props.setSnackBar({ visibility: true, severity: "success", message: "Success" });
          props.setFormRender(formRender + 1);
          handleDialogClose();
        } else {
          console.error(body.message);
          setSnackBar({ visibility: true, severity: "success", message: "Failed" });
        }
        return null;
      } catch (error) {
        console.error(error);
        return error;
      }
    }
  };

  useEffect(() => {}, [props.dialogOpen]);

  return (
    <Dialog open={props.dialogOpen} fullWidth className={classes.dialog}>
      <DialogTitle id="form-dialog-title">Request Form</DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <DialogContentText>Please enter the following details</DialogContentText>
        <TextField
          autoFocus
          required
          defaultValue={companyName}
          margin="dense"
          label="Partner Company Name"
          type="email"
          variant="outlined"
          fullWidth
          onChange={companyNameHandler}
          error={companyNameError}
        />
        {companyNameError && (
          <Typography variant="body2" className={classes.errorMsg}>
            Please enter Company Name
          </Typography>
        )}

        <TextField
          defaultValue={contactName}
          required
          margin="dense"
          label="Partner Contact Name"
          variant="outlined"
          fullWidth
          onChange={contactNameHandler}
          error={contactNameError}
        />
        {contactNameError && (
          <Typography variant="body2" className={classes.errorMsg}>
            Please enter Contact Name
          </Typography>
        )}

        <CountrySelect region={region} handleChange={handleRegion} error={regionError} />
        {regionError && (
          <Typography variant="body2" className={classes.errorMsg}>
            Please select a country
          </Typography>
        )}

        <TextField
          defaultValue={contactEmail}
          required
          margin="dense"
          variant="outlined"
          label="Partner Contact Email"
          fullWidth
          onChange={contactEmailHandler}
          error={contactEmailError || invalidEmail}
        />

        {contactEmailError && (
          <Typography variant="body2" className={classes.errorMsg}>
            Please enter Contact Email
          </Typography>
        )}
        {invalidEmail && (
          <Typography variant="body2" className={classes.errorMsg}>
            Please enter a valid Email
          </Typography>
        )}

        <TextField
          required
          defaultValue={channelManagerEmail}
          margin="dense"
          variant="outlined"
          label="Channel Manager Email"
          fullWidth
          onChange={channelManagerEmailHandler}
          error={channelManagerEmailError || invalidEmail2}
        />
        {channelManagerEmailError && (
          <Typography variant="body2" className={classes.errorMsg}>
            Please enter Channel Manager Email
          </Typography>
        )}

        {invalidEmail2 && (
          <Typography variant="body2" className={classes.errorMsg}>
            Please enter a valid Email
          </Typography>
        )}
        <Typography variant="body2" className={classes.infoMsg}>
          Partner form link will be automatically sent to partner contact email and channel manager will receive a
          confirmation email.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.addButton}
          variant="contained"
          color="secondary"
          size="small"
          onClick={addCompany}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Partner
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewResellerDialog;
