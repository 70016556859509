import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, TextField, Radio, RadioGroup, FormControlLabel, FormControl, Grid, Chip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AppConfig } from "../../../../../configs/admin-config";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    color: "white",
  },
  questionParent: {
    display: "flex",
  },
  questionIndex: {
    flexBasis: "4%",
    "@media(max-width: 900px)": {
      flexBasis: "5%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "10%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "15%",
    },
  },
  questionDetails: {
    flexBasis: "96%",
    textAlign: "justify",
    "@media(max-width: 900px)": {
      flexBasis: "94%",
    },
    "@media(max-width: 600px)": {
      flexBasis: "90%",
    },
    "@media(max-width: 400px)": {
      flexBasis: "85%",
    },
  },
  verticalcenter: {
    display: "flex",
    flexDirection: "column",
    justifycontent: "center",
  },
  emailMsg: {
    color: "grey",
  },
  fileAttachment: {
    display: "flex",
    justifycontent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  fileChip: {
    marginLeft: 10,
  },
  uploadButton: {
    textTransform: "none",
  },
  linearProgress: {
    marginTop: 15,
    marginBottom: 5,
    width: "100%",
  },
}));

const SubQuestionDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const question = props.question;
  const subQuestion = props.subQuestion;
  const category = props.category;

  const [boolAnswer, setBoolAnswer] = useState(false);
  const [uploadedFiles, setFiles] = useState([]);

  useEffect(() => {
    props.disableCall(props.fieldDisabled, props.answer);
    // Converting Mysql 0/1 into Boolean True/False
    if (props.answer && props.answer[0] && props.answer[0].booleanAnswer) {
      setBoolAnswer(true);
    } else if (props.answer && props.answer[0] && !props.answer[0].booleanAnswer) {
      setBoolAnswer(false);
    }

    if (props.files && props.files.length > 0) {
      props.files.map((file) => {
        if (file.subQuestionId === subQuestion.subQuestionId) {
          setFiles((prev) => [...prev, file]);
        }
        return null;
      });
    }
  }, [subQuestion.subQuestionId, subQuestion.answerType]);

  // Handle Radio Button Inputs
  const handleRadioChange = (subQuestionId) => (event) => {
    const value = event.target.value;
    // If yes is selected
    if (value === "yes") {
      setBoolAnswer(true);
      props.parentCallback(subQuestionId, true, "", "", true);
    }
    // If no is selected
    else if (value === "no") {
      setBoolAnswer(false);
      props.parentCallback(subQuestionId, false, "", "", true);
    }
  };

  // Handle Text Inputs
  const handleFormChange = (subQuestionId) => (event) => {
    const newValue = event.target.value;
    const currentValue = props.answer && props.answer[0] ? props.answer[0].descriptionAnswer : "";

    if (newValue !== currentValue) {
      props.parentCallback(subQuestionId, "", newValue, "", true);
    }
  };

  const handleChipOnClick = (fileName) => {
    var extension = fileName.substr(fileName.length - 4);
    var extensionWithoutDot = extension.substr(1);
    if (extension === ".pdf") {
      history.push({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.VIEW_PDF,
        state: { fileName: fileName, extension: extensionWithoutDot },
      });
    } else if (extension === ".jpg") {
      history.push({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.VIEW_IMAGE,
        state: { fileName: fileName, extension: extensionWithoutDot },
      });
    } else if (extension === ".jpeg") {
      history.push({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.VIEW_IMAGE,
        state: { fileName: fileName, extension: extensionWithoutDot },
      });
    } else if (extension === ".png") {
      history.push({
        pathname: AppConfig.urls.ADMIN + AppConfig.urls.VIEW_IMAGE,
        state: { fileName: fileName, extension: extensionWithoutDot },
      });
    } else {
      window.location.replace(AppConfig.FileServiceURL + fileName + "?fileExtension=" + extensionWithoutDot);
    }
  };

  return (
    <React.Fragment>
      {/* Render Boolean Input */}
      {question.questionId === subQuestion.questionId && subQuestion.answerType && (
        <Grid item xs={12}>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              <FormControl component="fieldset" disabled={props.fieldDisabled}>
                <RadioGroup
                  row
                  id={subQuestion.subQuestionId}
                  onChange={handleRadioChange(subQuestion.subQuestionId)}
                  defaultValue={props.answer && props.answer[0] ? (props.answer[0].booleanAnswer ? "yes" : "no") : ""}
                >
                  <FormControlLabel value="yes" control={<Radio color="primary" />} label="Approve" />
                  <FormControlLabel value="no" control={<Radio color="primary" />} label="Reject" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </Grid>
      )}

      {/* Render Description Input for Boolean Input Yes */}
      {question.questionId === subQuestion.questionId &&
        subQuestion.answerTypeDescription &&
        subQuestion.answerType &&
        boolAnswer && (
          <Grid item xs={12}>
            <div className={classes.questionParent}>
              <div className={classes.questionIndex}></div>
              <div className={classes.questionDetails}>
                <TextField
                  name={subQuestion.subQuestionId + ""}
                  defaultValue={props.answer && props.answer[0] ? props.answer[0].descriptionAnswer : ""}
                  size="small"
                  onChange={handleFormChange(subQuestion.subQuestionId)}
                  label={subQuestion.description}
                  autoComplete="nope"
                  error={props.isError}
                  helperText={props.isError ? "Required" : null}
                  variant="outlined"
                  disabled={props.answer[0].descriptionAnswer !== "" || props.fieldDisabled}
                  fullWidth
                />
              </div>
            </div>
          </Grid>
        )}

      {/* Render Description Input Only */}
      {question.questionId === subQuestion.questionId &&
        subQuestion.answerTypeDescription &&
        !subQuestion.answerType && (
          <Grid item xs={12}>
            <div className={classes.questionParent}>
              <div className={classes.questionIndex}></div>

              <div className={classes.questionDetails}>
                <TextField
                  name={subQuestion.subQuestionId + ""}
                  defaultValue={props.answer && props.answer[0] ? props.answer[0].descriptionAnswer : ""}
                  size="small"
                  onChange={handleFormChange(subQuestion.subQuestionId)}
                  label={subQuestion.description}
                  autoComplete="nope"
                  error={props.isError}
                  helperText={props.isError ? "Required" : null}
                  variant="outlined"
                  disabled={category.categoryId === 3 || (props.fieldDisabled ? true : false)}
                  minRows={category.categoryId === 7 ? 5 : 3}
                  multiline={category.categoryId === 7 || category.categoryId === 8}
                  fullWidth
                />
              </div>
            </div>
          </Grid>
        )}

      {/* Render File uploads */}
      {question.questionId === subQuestion.questionId && subQuestion.answerTypeFileUpload && (
        <Grid item xs={12}>
          <div className={classes.questionParent}>
            <div className={classes.questionIndex}></div>
            <div className={classes.questionDetails}>
              <Grid container spacing={2}>
                {subQuestion.description !== "" && (
                  <Grid item xs={12} sm={2} lg={1} className={classes.verticalcenter}>
                    <Typography>{subQuestion.description}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={10} lg={11}>
                  {uploadedFiles.map((file, key) => {
                    return (
                      <React.Fragment key={key}>
                        <Chip
                          className={classes.fileChip}
                          label={file.fileName.substring(24, file.fileName.length).substring(0, 18)}
                          color="secondary"
                          variant="outlined"
                          onClick={() => handleChipOnClick(file.fileName)}
                        ></Chip>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default SubQuestionDetails;
